<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.discount.crud.view }}</strong>
        </CCardHeader>
        <CCardBody>
          <table class="table table-bordered">
            <tr>
              <th>{{ $lang.discount.form.code }}</th>
              <td>{{ discount.code }}</td>
            </tr>
            <tr>
              <th>{{ $lang.discount.form.type }}</th>
              <td>{{ discount.type }}</td>
            </tr>
            <tr>
              <th>{{ $lang.discount.form.is_flat }}</th>
              <td v-if="discount.is_flat === 1">Yes</td>
              <td v-if="discount.is_flat === 0">No</td>
            </tr>
            <tr :hidden="persontageField">
              <th>{{ $lang.discount.form.percentage }}</th>
              <td>{{ discount.percentage }}</td>
            </tr>
            <tr :hidden="amountField">
              <th>{{ $lang.discount.form.amount }}</th>
              <td>{{ discount.amount }}</td>
            </tr>
            <tr>
              <th>{{ $lang.discount.form.start_date }}</th>
              <td>{{ getDateWithFormat(discount.start_date) }}</td>
            </tr>
            <tr>
              <th>{{ $lang.discount.form.end_date }}</th>
              <td>{{ getDateWithFormat(discount.end_date) }}</td>
            </tr>
          </table>
        </CCardBody>
        <CCardFooter>
          <CButton color="danger" size="sm" @click="cancel">
            <CIcon name="cil-ban"/>
            {{ $lang.buttons.general.crud.cancel }}
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {discount, users, discountUser} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import moment from "moment";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "DiscountCreate",
  mixins: [Mixin],
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        validCharactersOnly: true,
      },
      submitted: false,
      amountField: "",
      persontageField: "",
      userField: "",
      companyField: "",
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: discount,
      moduleUser: users,
      moduleDiscountUser: discountUser,
      userOptions: [],
      editId: this.$route.params.id,
      discount: {
        is_flat: "",
        percentage: "",
        amount: "",
        type: "",
        start_date: "",
        end_date: "",
        code: "",
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    axios
        .get(this.viewUrlApi(this.moduleDiscountUser, self.editId))
        .then((response) => {
          let discount = response.data.data;
          self.discount.is_flat = discount.isFlat;
          if (discount.isFlat === 1) {
            self.amountField = false;
            self.persontageField = true;
            self.discount.percentage = "";
            self.discount.amount = discount.amount;
          } else {
            self.amountField = true;
            self.persontageField = false;
            self.discount.percentage = discount.percentage;
            self.discount.amount = "";
          }
          if (discount.type === "External") {
            self.userField = true;
            self.companyField = false;
          } else {
            self.userField = false;
            self.companyField = true;
          }
          self.discount.type = discount.type;
          self.discount.start_date = discount.startDate;
          self.discount.end_date = discount.endDate;
          self.discount.code = discount.code;
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/discount-users"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
